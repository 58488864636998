<template>
  <div>
    <div style="padding: 4px 4px 0 4px; display: inline-block; border: solid 1px rgba(0, 0, 0, 0.1);">
      <img :src="src" style="opacity: 0.1;"/>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    src: require('../../assets/img/under-construction.png')
  })
}
</script>

<style>

</style>
